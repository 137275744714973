var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"myswitch"},[_c('div',{staticClass:"out",style:({
      borderRadius: _vm.borderRadius,
    }),on:{"click":function($event){$event.stopPropagation();return _vm.toggle.apply(null, arguments)}}},[_c('div',{staticClass:"inner-item",style:({
        borderRadius: _vm.borderRadius,
        background: _vm.visible == 1 ? '#7F76BD' : '#fff',
        color: _vm.visible == 1 ? '#fff' : '',
        marginRight: '4px',
      })},[_vm._v(" "+_vm._s(_vm.leftText)+" ")]),_c('div',{staticClass:"inner-item",style:({
        borderRadius: _vm.borderRadius,
        background: _vm.visible == 0 ? '#7F76BD' : '#fff',
        color: _vm.visible == 0 ? '#fff' : '',
      })},[_vm._v(" "+_vm._s(_vm.rightText)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }