<template>
  <!-- 首页 -->
  <!-- 顶部栏 -->
  <div class="contatain">
    <div class="tab">
      <!-- tab栏 -->
      <div
        class="tab-item"
        v-for="item in tabMenu"
        :key="item.id"
        :class="curTabIndex === item.id ? 'tab-item-active' : ''"
        @click="curTabIndex = item.id"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="flex-between">
      <div class="creatNewBtn" @click="addNew">新建账号</div>
      <div style="display: flex">
        <el-input
          size="mini"
          v-model="searchVal"
          placeholder="请输入关键字"
        ></el-input>
        <el-button style="margin-left: 24px" size="mini">批量管理</el-button>
      </div>
    </div>
    <div class="content">
      <!-- 全部职员table -->
      <template v-if="curTabIndex === 1">
        <div
          class="item"
          v-for="(item, i) in UserList"
          :key="i"
          :style="{ background: i % 2 !== 0 ? '#fff' : 'rgba(250,249,255,1)' }"
          @click="modify(item)"
        >
          <el-checkbox></el-checkbox>
          <!-- 剧本序号 -->
          <div class="item-index">{{ i + 1 }}</div>
          <!-- 剧本题目 -->
          <div style="width: 20%; margin-left: 15px">{{ item.userName }}</div>
          <!-- 剧本标签信息 -->
          <div style="width: 12%">{{ item.realName }}</div>

          <div style="width: 20%">{{ item.roleName }}</div>
          <div style="width: 48%" class="flex-around">
            <div class="resetBtn" @click="resetPassword(item.id)">重置密码</div>
            <myswitch
              :value="item.enabled"
              leftText="正常"
              rightText="锁定"
              @input="lockUser($event, item.id)"
            />
            <div class="delBtn" @click="delUser(item.id)">删除账号</div>
          </div>
        </div>
      </template>

      <!-- 权限设置table -->
      <template v-else>
        <div
          class="item"
          v-for="(item, i) in clientList"
          :key="i"
          :style="{ background: i % 2 !== 0 ? '#fff' : 'rgba(250,249,255,1)' }"
        >
          <el-checkbox></el-checkbox>
          <!-- 剧本序号 -->
          <div class="item-index">{{ i + 1 }}</div>
          <div style="width: 20%; margin-left: 10%">超级作者</div>
          <div style="width: 60%" class="flex-end">
            <div class="resetBtn">权限分配</div>
            <div class="delBtn" style="margin-left: 36px">删除类型</div>
          </div>
        </div>
      </template>

      <el-pagination
        class="pagination"
        :total="100"
        :current-page="1"
        :page-size="30"
        layout="prev, pager, next"
      ></el-pagination>
    </div>
    <!-- 新增修改用户弹窗 -->
    <el-dialog :visible.sync="accountDialog" width="30%">
      <div slot="title" style="text-align: center">
        <span style="background: #eceafa; padding: 5px">{{
          add_type === 1 ? "新建账号" : "修改账号"
        }}</span>
      </div>
      <div class="flex">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>登陆名称
        </div>
        <el-input
        onkeyup="value=value.replace(/[\u4E00-\u9FA5]/g,'')"
          style="margin-left: 10px; width: 212px"
          size="mini"
          placeholder="请输入用户名"
          v-model="userFrom.username"
        ></el-input>
      </div>
      <div class="flex">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>昵名
        </div>
        <el-input
          style="margin: 8px 0 0 10px; width: 212px"
          size="mini"
          placeholder="请输入昵称"
          v-model="userFrom.nickname"
        ></el-input>
      </div>
      <div class="flex">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>真实姓名
        </div>
        <el-input
          style="margin: 8px 0 0 10px; width: 212px"
          size="mini"
          placeholder="请输入真实姓名"
          v-model="userFrom.realName"
        ></el-input>
      </div>
      <div class="flex">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>类型
        </div>
        <el-select
          size="mini"
          style="margin: 8px 0 0 10px; width: 212px"
          v-model="userFrom.roleId"
          placeholder="请选择"
        >
          <el-option
            v-for="item in clientList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveUserMsg">保存</el-button>
      </span>
    </el-dialog>
    <!-- 权限弹窗 -->
    <el-dialog :visible.sync="authDialog" width="30%" center>
      <div slot="title" style="text-align: center">
        <span style="background: #eceafa; padding: 5px">{{
          add_type === 1 ? "新建类型" : "修改类型"
        }}</span>
      </div>
      <div class="flex">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>类型名称
        </div>
        <el-input
          style="margin: 8px 0 0 10px; width: 212px"
          size="mini"
        ></el-input>
      </div>
      <div class="title" style="margin-top: 30px">
        <span style="color: red; font-size: 16px">*</span>权限分配
      </div>
      <el-tree
        :data="treeData"
        :props="props"
        show-checkbox
        @check-change="handleCheckChange"
      >
      </el-tree>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="authDialog = false">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import myswitch from "./compts/myswitch";
export default {
  name: "Home",
  components: {
    myswitch,
  },
  data() {
    return {
      tabMenu: [
        { name: "全部职员", id: 1 },
        { name: "权限管理", id: 2 },
      ],
      curTabIndex: 1,
      searchVal: "",
      UserList: [],
      clientList: [],
      userFrom: {
        username: "",
        realName: "",
        nickname: "",
        roleId: "",
      },
      list: [],
      customerModel: {
        clientId: "customer",
        searchName: "",
        currPage: 1,
        limit: 20,
      },
      clientId: "internal",
      internalModel: {
        clientId: "internal",
        searchName: "",
        currPage: 1,
        limit: 20,
      },
      accountDialog: false,
      authDialog: false,
      add_type: 1,
      // 树
      treeData: [],
      props: {
        label: "name",
        children: "children",
      },
      count: 1,
    };
  },
  mounted() {
    this.getClinetUsersList();
    this.getAllClientMsg();
  },
  watch: {},
  methods: {
    handleCheckChange() {},
    
    async getAllClientMsg() {
      const res = await this.getRequest.getAllClientMsg({
        clientId: this.clientId,
      });
      if (res.respCode === 0) {
        this.clientList = res.datas;
      }
    },
    addNew() {
      this.curTabIndex === 1
        ? (this.accountDialog = true)
        : (this.authDialog = true);
      this.add_type = 1;
      this.userFrom = this.$options.data().userFrom;
    },
    async getClinetUsersList() {
      const res = await this.getRequest.getClinetUsersList(this.internalModel);
      this.UserList = res.data;
    },
    async lockUser(enabled, id) {
      const res = await this.getRequest.modifyUserStatus({
        id,
        enabled,
      });
      if (res.respCode === 0) {
        this.$message.success(res.respMsg);
      } else {
        this.$message.error(res.respMsg);
      }
      this.getClinetUsersList();
    },
    async saveUserMsg() {
      const res = await this.getRequest.addUserMsg(this.userFrom);
      if (res.respCode === 0) {
        this.$message.success(res.respMsg);
        this.userFrom = this.$options.data().userFrom;
        this.getClinetUsersList();
      } else {
        this.$message.error(res.respMsg);
      }
      this.accountDialog = false;
    },
    modify(item) {
      this.curTabIndex === 1
        ? (this.accountDialog = true)
        : (this.authDialog = true);
      this.add_type = 2;
      this.userFrom = item
    },
    resetPassword(id) {
      this.$confirm("是否重置该用户密码?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const res = await this.getRequest.resetPassword(id);
        this.$message({
          type: "success",
          message: res.respMsg,
        });
      });
    },
    // 删除内部用户
    delUser(id) {
      this.$confirm("此操作永久删除该用户, 是否继续?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      })
        .then(async () => {
          const res = await this.getRequest.delUser(id);
          this.getClinetUsersList();
          this.$message({
            type: "success",
            message: res.respMsg,
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  align-items: center;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.contatain {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  // background: #fff;
}
.content {
  padding: 16px 16px;

  position: relative;
  flex: 1;
  background: #fff;
  box-shadow: 2px 2px 10px 0px rgba(38, 28, 87, 0.11);

  border-radius: 4px;
}
.tab {
  display: flex;
  &-item {
    width: 96px;
    height: 24px;
    border-radius: 4px;
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    line-height: 24px;
    color: #000000;
    cursor: pointer;
    margin-right: 35px;
  }
  &-item-active {
    color: #fff;
    background: #7f76bd;
  }
}
.creatNewBtn {
  background-image: url("../../assets/newscr_btn_n@2x.png");
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  width: 212px;
  height: 24px;
  line-height: 24px;
  font-size: 18px;
  margin: 8px 0;
  cursor: pointer;
}

.item {
  color: rgba(51, 51, 51, 1);
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  border-radius: 4px;
  margin-top: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
  .item-index {
    margin-left: 10px;
    min-width: 18px;
    height: 18px;
    border-radius: 4px;
    border: 2px solid rgba(197, 192, 224, 1);
    color: rgba(197, 192, 224, 1);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  }
  .item-title {
    margin-left: 10px;
    width: 25%;
  }
  .item-message {
    margin-left: 10px;
    width: 30%;
  }
  .item-other {
    width: 45%;
    display: flex;
    justify-content: space-around;
  }
  .item-check-true {
    height: 20px;
    width: 80px;
    line-height: 20px;
    text-align: center;
    border-radius: 4px;
    font-size: 14px;
    background: #ffffff;
    border: 1px solid #c5c0e0;
  }
  .item-check-false {
    width: 64px;
    height: 24px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #ffffff;
    line-height: 24px;
  }
  .item-date {
    color: rgba(153, 153, 153, 1);
    margin-left: 10px;
    // width: 30%;
    text-align: right;
  }
  .item-sale {
    width: 96px;
    height: 24px;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #7f76bd;
    line-height: 24px;
    text-align: right;
  }
  .item-state {
    width: 54px;
    display: flex;
    justify-content: flex-end;
    .state {
      font-size: 14px;
      width: 54px;
      height: 20px;
      border-radius: 4px;
      color: #fff;
      text-align: center;
      line-height: 20px;
    }
  }
}
.resetBtn {
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #c5c0e0;
  line-height: 20px;
  width: 80px;
  text-align: center;
  height: 20px;
  font-size: 14px;
  color: #333;
}
.delBtn {
  width: 80px;
  height: 20px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #f394ac;
  line-height: 20px;
  color: #f394ac;
  text-align: center;
}
.pagination {
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
}
.title {
  width: 96px;
  height: 24px;
  background: #eceafa;
  border-radius: 4px;
  line-height: 24px;
  text-align: center;
}
</style>
