<template>
  <!-- 开关组件 -->
  <div class="myswitch">
    <div
      @click.stop="toggle"
      class="out"
      :style="{
        borderRadius: borderRadius,
      }"
    >
      <div
        class="inner-item"
        :style="{
          borderRadius: borderRadius,
          background: visible == 1 ? '#7F76BD' : '#fff',
          color: visible == 1 ? '#fff' : '',
          marginRight: '4px',
        }"
      >
        {{ leftText }}
      </div>
      <div
        class="inner-item"
        :style="{
          borderRadius: borderRadius,
          background: visible == 0 ? '#7F76BD' : '#fff',
          color: visible == 0 ? '#fff' : '',
        }"
      >
        {{ rightText }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      default: 1,
    },
    borderRadius: {
      default: "4px",
    },
    leftText: {
      default: "左",
    },
    rightText: {
      default: "右",
    },
  },

  watch: {},
  methods: {
    toggle() {
      this.$emit("input");
    },
  },
};
</script>

<style lang='scss' scoped>
.myswitch {
  .out {
    cursor: pointer;
    height: 24px;
    width: auto;
    border: 1px solid rgba(221, 218, 236, 1);
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0 2px;
    .inner-item {
      height: 20px;
      line-height: 20px;
      width: 50%;
      font-size: 14px;
      padding: 0 5px;
    }
  }
}
</style>